import React, { Component, useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

//MUI
import { LineChart, XAxis, YAxis, CartesianGrid, Legend, Line } from '@mui/x-charts';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
// import {LineChart} from '@mui/x-charts';
import Box from '@mui/material/Box';
import PrintIcon from '@mui/icons-material/Print';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import { Typography } from '@mui/material';

const StarchGraph = props => {
    const navigate = useNavigate();
    const state = useSelector(s => s);
    const dispatch = useDispatch();
    const [FiveRecords, setFiveRecords] = useState(true);
    const [TenRecords, setTenRecords] = useState(false);
    const [FifteenRecords, setFifteenRecords] = useState(false);
    const [TwentyRecords, setTwentyRecords] = useState(false);
    const [SelectedRecordValue, setSelectedRecordValue] = useState(5);
    const [SelectedRecords, setSelectedRecords] = useState([]);
    const [finalObj, setfinalObj] = useState(null);
    const [numberOfRecords, setnumberOfRecords] = useState([0, 1, 2, 3, 4, 5  ]);
    const [ModalOpen, setModalOpen] = useState(false);
    const [IsCelcius, setIsCelcius] = useState(false);
    
    
    useEffect(() => {
      console.log("state-------            state-------            state-------            state-------            state-------            state-------            ", state);
      
        let temp = state.StartchGraphRecord.filter(f => f.plantID == props.plantID && !f.deleted);
        setSelectedRecords(temp)      
    }, [state]);
    
    useEffect(() => {
        let Obj = {
            RecordCount: SelectedRecordValue,
            DataSource: [],
            PlantData: null,
            StorageGraph: {
              viscocity: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Holding Tank Viscosity",
                array: []
              },
              gelTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Holding Tank Gel Temperature",
                array: []
              },
              finTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Holding Tank Temperature",
                array: []
              }
            },
            DoserData: {
              viscocity: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser Viscosity",
                array: []
              },
              gelTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser Gel Temperature",
                array: []
              },
              finTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser Temperature",
                array: []
              }
            },
            bBatchData: {
              viscocity: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Batch Viscosity",
                array: []
              },
              gelTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Batch Gel Temperature",
                array: []
              },
              finTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Batch Finished Temperature",
                array: []
              }
            },
            DoserSFOne: {
              viscocity: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser SF #1 Viscosity",
                array: []
              },
              gelTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser SF #1 Gel Temperature",
                array: []
              },
              finTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser SF #1 Temperature",
                array: []
              }
            },
            DoserSFTwo: {
              viscocity: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser SF #2  Viscosity",
                array: []
              },
              gelTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser SF #2  Gel Temperature",
                array: []
              },
              finTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser SF #2 Temperature",
                array: []
              }
            },
            DoserDb: {
              viscocity: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser DB Viscosity",
                array: []
              },
              gelTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser DB Gel Temperature",
                array: []
              },
              finTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser DB Temperature",
                array: []
              }
            },
            Chart: {
              Type: "Line",
              Title: "Monthly Sales Data",
              XAxis: {
                Title: "Date",
                DataField: "Date"
              }
            }
          }
          setfinalObj(null)
        if (SelectedRecords) {
          SelectedRecords.forEach(e => {
            if (e.location === 1) {
                if (Obj.StorageGraph.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                    let temp = {
                        yVal: Obj.StorageGraph.gelTemp.array.length + 1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.StorageGraph.gelTemp.array.unshift(temp)
                }
                if (Obj.StorageGraph.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                    let temp = {
                        yVal: Obj.StorageGraph.viscocity.array.length + 1,
                        xVal: e.value,
                        dateValue: e.createdDate 
                      }
                      Obj.StorageGraph.viscocity.array.unshift(temp)
                }
                if (Obj.StorageGraph.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                    let temp = {
                        yVal: Obj.StorageGraph.finTemp.array.length + 1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: e.createdDate 
                      }
                    Obj.StorageGraph.finTemp.array.unshift(temp)
                }
                
            } 
            if (e.location === 0) {
                if (Obj.bBatchData.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                    let temp = {
                        yVal: Obj.bBatchData.viscocity.array.length + 1,
                        xVal: e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.bBatchData.viscocity.array.unshift(temp)
                }
                if (Obj.bBatchData.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                    let temp = {
                        yVal: Obj.bBatchData.gelTemp.array.length + 1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.bBatchData.gelTemp.array.unshift(temp)
                }
                if (Obj.bBatchData.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                    let temp = {
                        yVal: Obj.bBatchData.finTemp.array.length + 1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.bBatchData.finTemp.array.unshift(temp)
                }
            } 
            if (e.location === 2 || e.location === 3 || e.location === 4) {
                if (Obj.DoserData.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                    let temp = {
                        yVal: Obj.DoserData.viscocity.array.length + 1,
                        xVal: e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.DoserData.viscocity.array.unshift(temp)
                }
                if (Obj.DoserData.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                    let temp = {
                        yVal: Obj.DoserData.gelTemp.array.length + 1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.DoserData.gelTemp.array.unshift(temp)
                }
                if (Obj.DoserData.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                    let temp = {
                        yVal: Obj.DoserData.finTemp.array.length + 1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.DoserData.finTemp.array.unshift(temp)
                }
            } 
            if (e.location === 2) {
                if (Obj.DoserSFOne.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                    let temp = {
                        yVal: Obj.DoserSFOne.viscocity.array.length + 1,
                        xVal: e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.DoserSFOne.viscocity.array.unshift(temp)
                }
                if (Obj.DoserSFOne.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                    let temp = {
                        yVal: Obj.DoserSFOne.gelTemp.array.length + 1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.DoserSFOne.gelTemp.array.unshift(temp)
                }
                if (Obj.DoserSFOne.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                    let temp = {
                        yVal: Obj.DoserSFOne.finTemp.array.length + 1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.DoserSFOne.finTemp.array.unshift(temp)
                }
            } 
             if (e.location === 3) {
                if (Obj.DoserSFTwo.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                    let temp = {
                        yVal: Obj.DoserSFTwo.viscocity.array.length + 1,
                        xVal: e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.DoserSFTwo.viscocity.array.unshift(temp)
                }
                if (Obj.DoserSFTwo.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                    let temp = {
                        yVal: Obj.DoserSFTwo.gelTemp.array.length + 1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.DoserSFTwo.gelTemp.array.unshift(temp)
                }
                if (Obj.DoserSFTwo.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                    let temp = {
                        yVal: Obj.DoserSFTwo.finTemp.array.length + 1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.DoserSFTwo.finTemp.array.unshift(temp)
                }
            } 
            if (e.location === 4) {
                if (Obj.DoserDb.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                    let temp = {
                        yVal: Obj.DoserDb.viscocity.array.length + 1,
                        xVal: e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.DoserDb.viscocity.array.unshift(temp)
                }
                if (Obj.DoserDb.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                    let temp = {
                        yVal: Obj.DoserDb.gelTemp.array.length + 1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.DoserDb.gelTemp.array.unshift(temp)
                }
                if (Obj.DoserDb.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                    let temp = {
                        yVal: Obj.DoserDb.finTemp.array.length + 1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: e.createdDate 
                    }
                    Obj.DoserDb.finTemp.array.unshift(temp)
                }
           } 
          });

          if (Obj.StorageGraph.gelTemp.array.length > 0) {
            Obj.StorageGraph.gelTemp.dateragnge = 'From: ' +  moment(Obj.StorageGraph.gelTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.StorageGraph.gelTemp.array[Obj.StorageGraph.gelTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.StorageGraph.viscocity.array.length > 0) {
            Obj.StorageGraph.viscocity.dateragnge = 'From: ' +  moment(Obj.StorageGraph.viscocity.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.StorageGraph.viscocity.array[Obj.StorageGraph.viscocity.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.StorageGraph.finTemp.array.length > 0) {
            Obj.StorageGraph.finTemp.dateragnge = 'From: ' +  moment(Obj.StorageGraph.finTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.StorageGraph.finTemp.array[Obj.StorageGraph.finTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.bBatchData.viscocity.array.length > 0) {
            Obj.bBatchData.viscocity.dateragnge = 'From: ' +  moment(Obj.bBatchData.viscocity.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.bBatchData.viscocity.array[Obj.bBatchData.viscocity.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.bBatchData.gelTemp.array.length > 0) {
            Obj.bBatchData.gelTemp.dateragnge = 'From: ' +  moment(Obj.bBatchData.gelTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.bBatchData.gelTemp.array[Obj.bBatchData.gelTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.bBatchData.finTemp.array.length > 0) {
            Obj.bBatchData.finTemp.dateragnge = 'From: ' +  moment(Obj.bBatchData.finTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.bBatchData.finTemp.array[Obj.bBatchData.finTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserData.viscocity.array.length > 0) {
            Obj.DoserData.viscocity.dateragnge = 'From: ' +  moment(Obj.DoserData.viscocity.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserData.viscocity.array[Obj.DoserData.viscocity.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserData.gelTemp.array.length > 0) {
            Obj.DoserData.gelTemp.dateragnge = 'From: ' +  moment(Obj.DoserData.gelTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserData.gelTemp.array[Obj.DoserData.gelTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserData.finTemp.array.length > 0) {
            Obj.DoserData.finTemp.dateragnge = 'From: ' +  moment(Obj.DoserData.finTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserData.finTemp.array[Obj.DoserData.finTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserSFOne.viscocity.array.length > 0) {
            Obj.DoserSFOne.viscocity.dateragnge = 'From: ' +  moment(Obj.DoserSFOne.viscocity.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserSFOne.viscocity.array[Obj.DoserSFOne.viscocity.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserSFOne.gelTemp.array.length > 0) {
            Obj.DoserSFOne.gelTemp.dateragnge = 'From: ' +  moment(Obj.DoserSFOne.gelTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserSFOne.gelTemp.array[Obj.DoserSFOne.gelTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserSFOne.finTemp.array.length > 0) {
            Obj.DoserSFOne.finTemp.dateragnge = 'From: ' +  moment(Obj.DoserSFOne.finTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserSFOne.finTemp.array[Obj.DoserSFOne.finTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserSFTwo.viscocity.array.length > 0) {
            Obj.DoserSFTwo.viscocity.dateragnge = 'From: ' +  moment(Obj.DoserSFTwo.viscocity.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserSFTwo.viscocity.array[Obj.DoserSFTwo.viscocity.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserSFTwo.gelTemp.array.length > 0) {
            Obj.DoserSFTwo.gelTemp.dateragnge = 'From: ' +  moment(Obj.DoserSFTwo.gelTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserSFTwo.gelTemp.array[Obj.DoserSFTwo.gelTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserSFTwo.finTemp.array.length > 0) {
            Obj.DoserSFTwo.finTemp.dateragnge = 'From: ' +  moment(Obj.DoserSFTwo.finTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserSFTwo.finTemp.array[Obj.DoserSFTwo.finTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserDb.viscocity.array.length > 0) {
            Obj.DoserDb.viscocity.dateragnge = 'From: ' +  moment(Obj.DoserDb.viscocity.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserDb.viscocity.array[Obj.DoserDb.viscocity.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserDb.gelTemp.array.length > 0) {
            Obj.DoserDb.gelTemp.dateragnge = 'From: ' +  moment(Obj.DoserDb.gelTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserDb.gelTemp.array[Obj.DoserDb.gelTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserDb.finTemp.array.length > 0) {
            Obj.DoserDb.finTemp.dateragnge = 'From: ' +  moment(Obj.DoserDb.finTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserDb.finTemp.array[Obj.DoserDb.finTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
        }
        
        setfinalObj(Obj)

        console.log('Obj', Obj)

        if (SelectedRecordValue === 5) {
          setnumberOfRecords([0, 1, 2, 3, 4, 5])
        }
        if (SelectedRecordValue === 10) {
          setnumberOfRecords([0,1,2,3,4,5,6,7,8,9,10])
        }
        if (SelectedRecordValue === 15) {
          setnumberOfRecords([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15])
        }
        if (SelectedRecordValue === 20) {
          setnumberOfRecords([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20])
        }
        


    }, [SelectedRecordValue,SelectedRecords,IsCelcius]);

    const handleClick = (value) => {
        if (value === 5) {
            setFiveRecords(true)
            setTenRecords(false)
            setFifteenRecords(false)
            setTwentyRecords(false)
        }
        if (value === 10) {
            setFiveRecords(false)
            setTenRecords(true)
            setFifteenRecords(false)
            setTwentyRecords(false)
        }
        if (value === 15) {
            setFiveRecords(false)
            setTenRecords(false)
            setFifteenRecords(true)
            setTwentyRecords(false)
        }
        if (value === 20) {
            setFiveRecords(false)
            setTenRecords(false)
            setFifteenRecords(false)
            setTwentyRecords(true)
        }
        setSelectedRecordValue(value)
    };
    const handlePrint = () => {
        // const queryString = new URLSearchParams({ count: SelectedRecordValue }).toString();
        // navigate(`/PlantStarchCheckGraph/${props.plantID}?${queryString} `)
        setModalOpen(true)
    }
    

      function extractXValues(data) {
          const xValues = data.map(item => item.xVal);
          xValues.unshift(null);
          return xValues;
      }
    return (<>
        <Card style={{ position: 'relative'}} >
            <CardHeader title="Graph of Starch Report" />
            <div style={{ position: "absolute", right: '20px' , top: '20px'}}>
            <Button
                variant="contained"
                startIcon={<PrintIcon />}
                onClick={handlePrint}
            >
                Print
            </Button>
            </div>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px'}}>
                        Temperature:
                            <Chip onClick={ () => setIsCelcius(false)} label="Fahrenheit (°F)" color="primary" variant={!IsCelcius ? 'filled' : 'outlined'} />
                            <Chip onClick={ () => setIsCelcius(true)} label="Celsius (°C)" color="primary" variant={IsCelcius ? 'filled' : 'outlined'} />
                        </div>
                    </Grid>
                    <Grid item xs>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '12px'}}>
                            Data Range:
                            <Chip onClick={ () => handleClick(5)} label="5 Records" color="primary" variant={FiveRecords ? 'filled' : 'outlined'} />
                            <Chip onClick={ () => handleClick(10)} label="10 Records" color="primary" variant={TenRecords ? 'filled' : 'outlined'} />
                            <Chip onClick={ () => handleClick(15)} label="15 Records" color="primary" variant={FifteenRecords ? 'filled' : 'outlined'} />
                            <Chip onClick={ () => handleClick(20)} label="20 Records" color="primary" variant={TwentyRecords ? 'filled' : 'outlined'} />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        { finalObj ?
                            <Grid container spacing={3}>
                                <Grid item xs={12}>

                                  <Box sx={{ border: 1, padding: 4 }}>
                                  <Typography variant="h5">Mixer Chart</Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500}}>
                                      <LineChart
                                        xAxis={[{ data: numberOfRecords }]}
                                        // yAxis={[{ min: 20 }]}  // Set the minimum value for the Y-axis
                                        series={[
                                          { curve: "linear", data: extractXValues(finalObj.bBatchData.viscocity.array) ,label: 'Viscosity' },
                                          { curve: "linear", data: extractXValues(finalObj.bBatchData.gelTemp.array), label: `${IsCelcius ? 'Gel Temp (°C)' : 'Gel Temp (°F)'}` },
                                          { curve: "linear", data: extractXValues(finalObj.bBatchData.finTemp.array), label: `${IsCelcius ? 'Finished Temp (°C)' : 'Finished Temp (°F)'}`   },
                                          ]}

                                        width={SelectedRecordValue === 5 ? 499 : SelectedRecordValue === 10 ? 600 : 900}
                                        height={300}
                                        >
                                        </LineChart>
                                    </Box>
                                  <Typography variant="h6">Date range:</Typography>
                                    <Box >
                                    <div style={{display: 'flex'}}> 
                                      <div style={{ width: '200px', minWidth: '200px' }}>Viscosity:</div> {finalObj.bBatchData.viscocity.dateragnge}</div>
                                    <div style={{display: 'flex'}}> 
                                      <div style={{ width: '200px', minWidth: '200px' }}>Gel Temperature:</div>  {finalObj.bBatchData.gelTemp.dateragnge}</div>
                                    <div style={{display: 'flex'}}> 
                                      <div style={{ width: '200px', minWidth: '200px' }}>Finished Temperature:</div>  {finalObj.bBatchData.finTemp.dateragnge}</div>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={SelectedRecordValue === 5 ? 4: SelectedRecordValue === 10 ? 6 : 12}>

                                  <Box sx={{ border: 1, padding: 4 }}>
                                  <Typography variant="h5">Doser Viscosity </Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                      <LineChart
                                        xAxis={[{ data: numberOfRecords }]}
                                        series={[
                                          { curve: "linear", data: extractXValues(finalObj.DoserSFOne.viscocity.array) ,label: 'SF #1' },
                                          { curve: "linear", data: extractXValues(finalObj.DoserSFTwo.viscocity.array), label: 'SF #2' },
                                          { curve: "linear", data: extractXValues(finalObj.DoserDb.viscocity.array), label: 'DB' },
                                          ]}
                                        width={SelectedRecordValue === 5 ? 450 : SelectedRecordValue === 10 ? 600 : 900}
                                        height={300}
                                        />
                                    </Box>
                                    <Typography variant="h6">Date range:</Typography>
                                    <Box >
                                      <div style={{display: 'flex'}}> 
                                      <div style={{ width: '100px', minWidth: '100px' }}>SF #1:</div> {finalObj.DoserSFOne.viscocity.dateragnge}</div>
                                      <div style={{display: 'flex'}}> 
                                      <div style={{ width: '100px', minWidth: '100px' }}>SF #2:</div>  {finalObj.DoserSFTwo.viscocity.dateragnge}</div>
                                      <div style={{display: 'flex'}}> 
                                      <div style={{ width: '100px', minWidth: '100px' }}>DB:</div>  {finalObj.DoserDb.viscocity.dateragnge}</div>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={SelectedRecordValue === 5 ? 4: SelectedRecordValue === 10 ? 6 : 12}>

                                  <Box sx={{ border: 1, padding: 4 }}>
                                  <Typography variant="h5">Doser Gel Temperature {IsCelcius ? ' (°C)' : ' (°F)'}</Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                      <LineChart
                                        xAxis={[{ data: numberOfRecords }]}
                                        series={[
                                          { curve: "linear", data: extractXValues(finalObj.DoserSFOne.gelTemp.array) ,label: 'SF #1' },
                                          { curve: "linear", data: extractXValues(finalObj.DoserSFTwo.gelTemp.array), label: 'SF #2' },
                                          { curve: "linear", data: extractXValues(finalObj.DoserDb.gelTemp.array), label: 'DB' },
                                          ]}
                                        width={SelectedRecordValue === 5 ? 450 : SelectedRecordValue === 10 ? 600 : 900}
                                        height={300}
                                        />
                                    </Box>
                                    <Typography variant="h6">Date range:</Typography>
                                    <Box >
                                      <div style={{display: 'flex'}}> 
                                      <div style={{ width: '100px', minWidth: '100px' }}>SF #1:</div> {finalObj.DoserSFOne.gelTemp.dateragnge}</div>
                                      <div style={{display: 'flex'}}> 
                                      <div style={{ width: '100px', minWidth: '100px' }}>SF #2:</div>  {finalObj.DoserSFTwo.gelTemp.dateragnge}</div>
                                      <div style={{display: 'flex'}}> 
                                      <div style={{ width: '100px', minWidth: '100px' }}>DB:</div>  {finalObj.DoserDb.gelTemp.dateragnge}</div>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={SelectedRecordValue === 5 ? 4: SelectedRecordValue === 10 ? 6 : 12}>

                                  <Box sx={{ border: 1, padding: 4 }}>
                                  <Typography variant="h5">Doser Temperature {IsCelcius ? ' (°C)' : ' (°F)'}</Typography>
                                    <Box sx={{ width: '100%', maxWidth: 500 }}>
                                      <LineChart
                                        xAxis={[{ data: numberOfRecords }]}
                                        series={[
                                          { curve: "linear", data: extractXValues(finalObj.DoserSFOne.finTemp.array) ,label: 'SF #1' },
                                          { curve: "linear", data: extractXValues(finalObj.DoserSFTwo.finTemp.array), label: 'SF #2' },
                                          { curve: "linear", data: extractXValues(finalObj.DoserDb.finTemp.array), label: 'DB' },
                                          ]}
                                        width={SelectedRecordValue === 5 ? 450 : SelectedRecordValue === 10 ? 600 : 900}
                                        height={300}
                                        />
                                    </Box>
                                    <Typography variant="h6">Date range:</Typography>
                                    <Box >
                                      <div style={{display: 'flex'}}> 
                                      <div style={{ width: '100px', minWidth: '100px' }}>SF #1:</div> {finalObj.DoserSFOne.finTemp.dateragnge}</div>
                                      <div style={{display: 'flex'}}> 
                                      <div style={{ width: '100px', minWidth: '100px' }}>SF #2:</div>  {finalObj.DoserSFTwo.finTemp.dateragnge}</div>
                                      <div style={{display: 'flex'}}> 
                                      <div style={{ width: '100px', minWidth: '100px' }}>DB:</div>  {finalObj.DoserDb.finTemp.dateragnge}</div>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid item xs={SelectedRecordValue === 5 ? 4: SelectedRecordValue === 10 ? 6 : 12}>

                                  <Box sx={{ border: 1, padding: 4 }}>
                                  <Typography variant="h5">Storage Tank Viscosity </Typography>
                                  <Box sx={{ width: '100%', maxWidth: 500 }}>
                                    <LineChart
                                      xAxis={[{ data: numberOfRecords }]}
                                      series={[
                                        { curve: "linear", data: extractXValues(finalObj.StorageGraph.viscocity.array) ,label: 'Viscosity ' },
                                        ]}
                                      width={SelectedRecordValue === 5 ? 450 : SelectedRecordValue === 10 ? 600 : 900}
                                      height={300}
                                      />
                                  </Box>
                                  <Typography variant="h6">Date range:</Typography>
                                    <Box >
                                      <div style={{display: 'flex'}}> 
                                        <div style={{ width: '100px', minWidth: '100px' }}>Viscosity:</div> {finalObj.StorageGraph.viscocity.dateragnge}
                                      </div>
                                    </Box>
                                </Box>
                                </Grid>
                                <Grid item xs={SelectedRecordValue === 5 ? 4: SelectedRecordValue === 10 ? 6 : 12}>

                                  <Box sx={{ border: 1, padding: 4 }}>
                                  <Typography variant="h5">Storage Tank Gel Temperature {IsCelcius ? ' (°C)' : ' (°F)'}</Typography>
                                  <Box sx={{ width: '100%', maxWidth: 500 }}>
                                    <LineChart
                                      xAxis={[{ data: numberOfRecords }]}
                                      series={[
                                        { curve: "linear", data: extractXValues(finalObj.StorageGraph.gelTemp.array), label: 'Gel Temperature ' },
                                        ]}
                                      width={SelectedRecordValue === 5 ? 450 : SelectedRecordValue === 10 ? 600 : 900}
                                      height={300}
                                      />
                                  </Box>
                                  <Typography variant="h6">Date range:</Typography>
                                    <Box >
                                      <div style={{display: 'flex'}}> 
                                        <div style={{ width: '200px', minWidth: '200px' }}>Gel Temperature:</div> {finalObj.StorageGraph.gelTemp.dateragnge}
                                      </div>
                                    </Box>
                                </Box>
                                </Grid>
                                <Grid item xs={SelectedRecordValue === 5 ? 4: SelectedRecordValue === 10 ? 6 : 12}>

                                  <Box sx={{ border: 1, padding: 4 }}>
                                  <Typography variant="h5">Storage Tank Temperature {IsCelcius ? ' (°C)' : ' (°F)'}</Typography>
                                  <Box sx={{ width: '100%', maxWidth: 500 }}>
                                    <LineChart
                                      xAxis={[{ data: numberOfRecords }]}
                                      series={[
                                        { curve: "linear", data: extractXValues(finalObj.StorageGraph.finTemp.array), label: 'finished Temperature' },
                                        ]}
                                      width={SelectedRecordValue === 5 ? 450 : SelectedRecordValue === 10 ? 600 : 900}
                                      height={300}
                                      />
                                  </Box>
                                  <Typography variant="h6">Date range:</Typography>
                                    <Box >
                                      <div style={{display: 'flex'}}> 
                                        <div style={{ width: '200px', minWidth: '200px' }}>finished Temperature:</div> {finalObj.StorageGraph.finTemp.dateragnge}
                                      </div>
                                    </Box>
                                </Box>
                                </Grid>
                                {/* <Grid item xs={SelectedRecordValue === 5 ? 4: SelectedRecordValue === 10 ? 6 : 12}>
                                  <Typography variant="h5">Storage</Typography>
                                  <Box sx={{ width: '100%', maxWidth: 500 }}>
                                    <LineChart
                                      xAxis={[{ data: numberOfRecords }]}
                                      series={[
                                        { curve: "linear", data: extractXValues(finalObj.StorageGraph.viscocity.array) ,label: 'Viscocity' },
                                        { curve: "linear", data: extractXValues(finalObj.StorageGraph.gelTemp.array), label: 'Gel Temp ' },
                                        { curve: "linear", data: extractXValues(finalObj.StorageGraph.finTemp.array), label: 'finished Temp' },
                                        ]}
                                      width={SelectedRecordValue === 5 ? 450 : SelectedRecordValue === 10 ? 600 : 900}
                                      height={300}
                                      />
                                  </Box>
                                </Grid> */}
                            </Grid>: <Grid container spacing={3}>
                                <Grid item xs>
                                Loading.....
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <PrintOptions plantID={props.plantID} IsCelcius={IsCelcius} SelectedRecordValue={SelectedRecordValue} modalOpen={ModalOpen} closeModal={() => setModalOpen(false)}
        />
    </>
    );
}
export default StarchGraph;

export const PrintOptions = props => {
  const [FiveRecords, setFiveRecords] = useState(false);
  const [TenRecords, setTenRecords] = useState(false);
  const [FifteenRecords, setFifteenRecords] = useState(false);
  const [TwentyRecords, setTwentyRecords] = useState(false);
  const [SelectedRecordValue, setSelectedRecordValue] = useState(props.SelectedRecordValue);
  const [IsCelcius, setIsCelcius] = useState(props.IsCelcius);
  const navigate = useNavigate();


  useEffect(() => {
    console.log('ModalOpen change333333333333333333d:', SelectedRecordValue);

    if (props.modalOpen) {
      setSelectedRecordValue(props.SelectedRecordValue);
      setIsCelcius(props.IsCelcius);
      // Optionally, reset the record states
      if (props.SelectedRecordValue === 5) {
        setFiveRecords(true)
        setTenRecords(false)
        setFifteenRecords(false)
        setTwentyRecords(false)
    }
    if (props.SelectedRecordValue === 10) {
        setFiveRecords(false)
        setTenRecords(true)
        setFifteenRecords(false)
        setTwentyRecords(false)
    }
    if (props.SelectedRecordValue === 15) {
        setFiveRecords(false)
        setTenRecords(false)
        setFifteenRecords(true)
        setTwentyRecords(false)
    }
    if (props.SelectedRecordValue === 20) {
        setFiveRecords(false)
        setTenRecords(false)
        setFifteenRecords(false)
        setTwentyRecords(true)
    }
    }
  }, [props.modalOpen]);
  const handleClick = (value) => {
    if (value === 5) {
        setFiveRecords(true)
        setTenRecords(false)
        setFifteenRecords(false)
        setTwentyRecords(false)
    }
    if (value === 10) {
        setFiveRecords(false)
        setTenRecords(true)
        setFifteenRecords(false)
        setTwentyRecords(false)
    }
    if (value === 15) {
        setFiveRecords(false)
        setTenRecords(false)
        setFifteenRecords(true)
        setTwentyRecords(false)
    }
    if (value === 20) {
        setFiveRecords(false)
        setTenRecords(false)
        setFifteenRecords(false)
        setTwentyRecords(true)
    }
    setSelectedRecordValue(value)
};
  const PrintNow = () => {
    const queryString = new URLSearchParams({ count: SelectedRecordValue, unit: IsCelcius }).toString();
    navigate(`/PlantStarchCheckGraph/${props.plantID}?${queryString} `)
  };
  return (
      <Dialog fullWidth maxWidth={"sm"} open={props.modalOpen} >
        <DialogTitle id="alert-dialog-title">Print records options</DialogTitle>
          <DialogContent>
              <Grid container spacing={3}>
                  <Grid item xs={12} sx={{ marginRight: '20px' }} >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '12px'}}>
                        Temperature:
                        <Chip onClick={ () => setIsCelcius(false)} label="Fahrenheit (°F)" color="primary" variant={!IsCelcius ? 'filled' : 'outlined'} />
                        <Chip onClick={ () => setIsCelcius(true)} label="Celsius (°C)" color="primary" variant={IsCelcius ? 'filled' : 'outlined'} />
                    </div>
                  </Grid>
                  <Grid item xs={12} sx={{ marginRight: '20px' }} >
                  <div style={{ display: 'flex', alignItems: 'center', gap: '12px'}}>
                            Data Range:
                            <Chip onClick={ () => handleClick(5)} label="5 Records" color="primary" variant={FiveRecords ? 'filled' : 'outlined'} />
                            <Chip onClick={ () => handleClick(10)} label="10 Records" color="primary" variant={TenRecords ? 'filled' : 'outlined'} />
                            <Chip onClick={ () => handleClick(15)} label="15 Records" color="primary" variant={FifteenRecords ? 'filled' : 'outlined'} />
                            <Chip onClick={ () => handleClick(20)} label="20 Records" color="primary" variant={TwentyRecords ? 'filled' : 'outlined'} />
                        </div>
                  </Grid>
              </Grid>
          </DialogContent>
          <DialogActions>
              <Button onClick={props.closeModal}>Close</Button>
              <Button disabled={SelectedRecordValue === 0 } onClick={PrintNow}>Print Now</Button>
          </DialogActions>
      </Dialog >
  );
};