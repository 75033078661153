


import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
import { GetPlantFormulas } from "./FormulaActions";
import { AddIngredientVerification, GetIngredientVerificationByVisitID } from "./IngredientVerificationActions";
import { AddNewHeatVessel, GetHeatVesselsByVisitID } from './HeatVesselActions';
import { AddNewGlueRoll, GetGlueRollsByVisitID } from './GlueRollActions';
import { AddNewGlueLine, GetGlueLinesByVisitID } from './GlueLineActions';
import { AddNewStarch, GetSKItemsByVisitID, GetStarchsByVisitID } from './StarchKitchenActions';
import { CreateBHSChecklist, GetChecklistsByVisitID } from './ChecklistActions';
import { GetKeyItemByVisitID } from "./KeyItemActions";
import { AddSyncTimestamp, CheckReSync } from './LastSyncActions';
import { GetPlantData } from './PlantActions';
import { GetThermalsByVisitID } from './ThermalImageActions';
const constants=Constants;

export const GetStarchGRPByPlantID = (PlantID) => {
    return (dispatch, getState) => {
        const state = getState();
        if (state.offline.online) {
            //No Data Get Data
            let fetchTask = fetch(Constants.URLs.Plantspecificgraph + '/' + PlantID, { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetStarchGRPByPlantID", PlantID, dispatch))
                .then(response => {
                   return response.json()
                })
                .then(StartchGrpRecords => {
                    let tempList = []
                    StartchGrpRecords.forEach(e => {
                        e.plantID = PlantID
                    });
                    tempList = StartchGrpRecords
                    console.log('tempList', tempList);
                    
                    dispatch({ type: Constants.Actions.StartchGrpRecords, data: tempList, plantID:PlantID })
                })
                .catch(FetchCatchHandler("GetStarchGRPByPlantID", PlantID, dispatch));
        }
    };
};
export const ClearStartchGrpRecords = () => {
    return { type: constants.Actions.StartchGrpRecords.ClearData };
}