import Constants from '../../constants';
//Libraries
import React, { useEffect, useContext, useState } from 'react';
import { createSelector } from 'reselect';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import  {useNavigate, useParams, useLocation } from 'react-router-dom';
import ReportViewer from '../Report/ReportViewer';
import { SelVisitByID,SelInventoryByVisitID,SelMeasuresByVisitID,FormatLocation,SelSKItemsByVisitID } from '../../selectors/VisitSelectors';
import { useReportUser,usePlantDataFromVisitID, useVisitData, useReportName } from '../../misc/UseFuncs';
import { getStore } from '../../core';
import moment from 'moment';
const PlantsSel = state=>state.Plants;
const PlantByID =createSelector(PlantsSel,(state,pID)=>pID,
(plants,pID)=>{
    return plants.find(p=>p.id===pID);
}
)
const StarchChecksGraphPrint = props => {
  const nagivate = useNavigate();
    const params = useParams();
    const PlantData = useSelector(s=>PlantByID(s,params.PlantID));
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const jsonData = queryParams.get('count');
    const IsCelcius = JSON.parse(queryParams.get('unit')); 
    
    const count = JSON.parse(jsonData);

    const state = useSelector(s => s);
    const dispatch = useDispatch();
    const [FiveRecords, setFiveRecords] = useState(true);
    const [TenRecords, setTenRecords] = useState(false);
    const [FifteenRecords, setFifteenRecords] = useState(false);
    const [SelectedRecordValue, setSelectedRecordValue] = useState(count);
    const [SelectedRecords, setSelectedRecords] = useState([]);
    const [finalObj, setfinalObj] = useState(null);

    
    useEffect(() => {
        console.log('tate.--tate.--tate.--tate.--tate.--tate.--tate.--tate.--tate.--tate.--tate.--tate.--tate.-- state.', state)
        if (state.StartchGraphRecord) {
          let temp = state.StartchGraphRecord.filter(f => f.plantID == params.PlantID && !f.deleted);
            if (temp) {
                setSelectedRecords(temp)        
            }
            
        }
              
    }, [state]);
    
    useEffect(() => {
      let Obj = {
        DataSource: {
          RecordCount: SelectedRecordValue,
        },
        PlantData: PlantData,
        StorageGraph: {
          viscocity: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Holding Tank Viscosity",
            array: []
          },
          gelTemp: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Holding Tank Gel Temperature",
            array: []
          },
          finTemp: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Holding Tank Temperature",
            array: []
          }
        },
        DoserData: {
          viscocity: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Doser Viscosity",
            array: []
          },
          gelTemp: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Doser Gel Temperature",
            array: []
          },
          finTemp: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Doser Temperature",
            array: []
          }
        },
        bBatchData: {
          viscocity: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Batch Viscosity",
            array: []
          },
          gelTemp: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Batch Gel Temperature",
            array: []
          },
          finTemp: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Batch Finished Temperature",
            array: []
          }
        },
        DoserSFOne: {
          viscocity: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Doser SF #1 Viscosity",
            array: []
          },
          gelTemp: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Doser SF #1 Gel Temperature",
            array: []
          },
          finTemp: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Doser SF #1 Temperature",
            array: []
          }
        },
        DoserSFTwo: {
          viscocity: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Doser SF #2  Viscosity",
            array: []
          },
          gelTemp: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Doser SF #2  Gel Temperature",
            array: []
          },
          finTemp: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Doser SF #2 Temperature",
            array: []
          }
        },
        DoserDb: {
          viscocity: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Doser DB Viscosity",
            array: []
          },
          gelTemp: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Doser DB Gel Temperature",
            array: []
          },
          finTemp: {
            dateragnge: '',
            isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
            title: "Doser DB Temperature",
            array: []
          }
        },
        Chart: {
          Type: "Line",
          Title: "Monthly Sales Data",
          XAxis: {
            Title: "Date",
            DataField: "Date"
          }
        }
      }
      if (SelectedRecords) {
        SelectedRecords.forEach(e => {
          
              if (e.location === 1) {
                  if (Obj.StorageGraph.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                      let temp = {
                          yVal: Obj.StorageGraph.gelTemp.array.length + 1,
                          xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.StorageGraph.gelTemp.array.unshift(temp)
                  }
                  if (Obj.StorageGraph.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                      let temp = {
                          yVal: Obj.StorageGraph.viscocity.array.length + 1,
                          xVal: e.value,
                          dateValue: e.createdDate 
                        }
                        Obj.StorageGraph.viscocity.array.unshift(temp)
                  }
                  if (Obj.StorageGraph.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                      let temp = {
                          yVal: Obj.StorageGraph.finTemp.array.length + 1,
                          xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                          dateValue: e.createdDate 
                        }
                      Obj.StorageGraph.finTemp.array.unshift(temp)
                  }
                  
              } 
              if (e.location === 0) {
                  if (Obj.bBatchData.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                      let temp = {
                          yVal: Obj.bBatchData.viscocity.array.length + 1,
                          xVal: e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.bBatchData.viscocity.array.unshift(temp)
                  }
                  if (Obj.bBatchData.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                      let temp = {
                          yVal: Obj.bBatchData.gelTemp.array.length + 1,
                          xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.bBatchData.gelTemp.array.unshift(temp)
                  }
                  if (Obj.bBatchData.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                      let temp = {
                          yVal: Obj.bBatchData.finTemp.array.length + 1,
                          xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.bBatchData.finTemp.array.unshift(temp)
                  }
              } 
              if (e.location === 2 || e.location === 3 || e.location === 4) {
                  if (Obj.DoserData.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                      let temp = {
                          yVal: Obj.DoserData.viscocity.array.length + 1,
                          xVal: e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.DoserData.viscocity.array.unshift(temp)
                  }
                  if (Obj.DoserData.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                      let temp = {
                          yVal: Obj.DoserData.gelTemp.array.length + 1,
                          xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.DoserData.gelTemp.array.unshift(temp)
                  }
                  if (Obj.DoserData.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                      let temp = {
                          yVal: Obj.DoserData.finTemp.array.length + 1,
                          xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.DoserData.finTemp.array.unshift(temp)
                  }
              } 
              if (e.location === 2) {
                  if (Obj.DoserSFOne.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                      let temp = {
                          yVal: Obj.DoserSFOne.viscocity.array.length + 1,
                          xVal: e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.DoserSFOne.viscocity.array.unshift(temp)
                  }
                  if (Obj.DoserSFOne.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                      let temp = {
                          yVal: Obj.DoserSFOne.gelTemp.array.length + 1,
                          xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.DoserSFOne.gelTemp.array.unshift(temp)
                  }
                  if (Obj.DoserSFOne.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                      let temp = {
                          yVal: Obj.DoserSFOne.finTemp.array.length + 1,
                          xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.DoserSFOne.finTemp.array.unshift(temp)
                  }
               } 
               if (e.location === 3) {
                  if (Obj.DoserSFTwo.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                      let temp = {
                          yVal: Obj.DoserSFTwo.viscocity.array.length + 1,
                          xVal: e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.DoserSFTwo.viscocity.array.unshift(temp)
                  }
                  if (Obj.DoserSFTwo.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                      let temp = {
                          yVal: Obj.DoserSFTwo.gelTemp.array.length + 1,
                          xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.DoserSFTwo.gelTemp.array.unshift(temp)
                  }
                  if (Obj.DoserSFTwo.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                      let temp = {
                          yVal: Obj.DoserSFTwo.finTemp.array.length + 1,
                          xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.DoserSFTwo.finTemp.array.unshift(temp)
                  }
              } 
              if (e.location === 4) {
                  if (Obj.DoserDb.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                      let temp = {
                          yVal: Obj.DoserDb.viscocity.array.length + 1,
                          xVal: e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.DoserDb.viscocity.array.unshift(temp)
                  }
                  if (Obj.DoserDb.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                      let temp = {
                          yVal: Obj.DoserDb.gelTemp.array.length + 1,
                          xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.DoserDb.gelTemp.array.unshift(temp)
                  }
                  if (Obj.DoserDb.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                      let temp = {
                          yVal: Obj.DoserDb.finTemp.array.length + 1,
                          xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                          dateValue: e.createdDate 
                      }
                      Obj.DoserDb.finTemp.array.unshift(temp)
                  }
             } 
          });
          // Obj.StorageGraph.viscocity.dateragnge = moment(FoorterData.CiAddedbySigTime).format('D-MMM-YY')

          if (Obj.StorageGraph.gelTemp.array.length > 0) {
            Obj.StorageGraph.gelTemp.dateragnge = 'From: ' +  moment(Obj.StorageGraph.gelTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.StorageGraph.gelTemp.array[Obj.StorageGraph.gelTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.StorageGraph.viscocity.array.length > 0) {
            Obj.StorageGraph.viscocity.dateragnge = 'From: ' +  moment(Obj.StorageGraph.viscocity.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.StorageGraph.viscocity.array[Obj.StorageGraph.viscocity.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.StorageGraph.finTemp.array.length > 0) {
            Obj.StorageGraph.finTemp.dateragnge = 'From: ' +  moment(Obj.StorageGraph.finTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.StorageGraph.finTemp.array[Obj.StorageGraph.finTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.bBatchData.viscocity.array.length > 0) {
            Obj.bBatchData.viscocity.dateragnge = 'From: ' +  moment(Obj.bBatchData.viscocity.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.bBatchData.viscocity.array[Obj.bBatchData.viscocity.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.bBatchData.gelTemp.array.length > 0) {
            Obj.bBatchData.gelTemp.dateragnge = 'From: ' +  moment(Obj.bBatchData.gelTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.bBatchData.gelTemp.array[Obj.bBatchData.gelTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.bBatchData.finTemp.array.length > 0) {
            Obj.bBatchData.finTemp.dateragnge = 'From: ' +  moment(Obj.bBatchData.finTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.bBatchData.finTemp.array[Obj.bBatchData.finTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserData.viscocity.array.length > 0) {
            Obj.DoserData.viscocity.dateragnge = 'From: ' +  moment(Obj.DoserData.viscocity.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserData.viscocity.array[Obj.DoserData.viscocity.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserData.gelTemp.array.length > 0) {
            Obj.DoserData.gelTemp.dateragnge = 'From: ' +  moment(Obj.DoserData.gelTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserData.gelTemp.array[Obj.DoserData.gelTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserData.finTemp.array.length > 0) {
            Obj.DoserData.finTemp.dateragnge = 'From: ' +  moment(Obj.DoserData.finTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserData.finTemp.array[Obj.DoserData.finTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserSFOne.viscocity.array.length > 0) {
            Obj.DoserSFOne.viscocity.dateragnge = 'From: ' +  moment(Obj.DoserSFOne.viscocity.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserSFOne.viscocity.array[Obj.DoserSFOne.viscocity.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserSFOne.gelTemp.array.length > 0) {
            Obj.DoserSFOne.gelTemp.dateragnge = 'From: ' +  moment(Obj.DoserSFOne.gelTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserSFOne.gelTemp.array[Obj.DoserSFOne.gelTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserSFOne.finTemp.array.length > 0) {
            Obj.DoserSFOne.finTemp.dateragnge = 'From: ' +  moment(Obj.DoserSFOne.finTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserSFOne.finTemp.array[Obj.DoserSFOne.finTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserSFTwo.viscocity.array.length > 0) {
            Obj.DoserSFTwo.viscocity.dateragnge = 'From: ' +  moment(Obj.DoserSFTwo.viscocity.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserSFTwo.viscocity.array[Obj.DoserSFTwo.viscocity.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserSFTwo.gelTemp.array.length > 0) {
            Obj.DoserSFTwo.gelTemp.dateragnge = 'From: ' +  moment(Obj.DoserSFTwo.gelTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserSFTwo.gelTemp.array[Obj.DoserSFTwo.gelTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserSFTwo.finTemp.array.length > 0) {
            Obj.DoserSFTwo.finTemp.dateragnge = 'From: ' +  moment(Obj.DoserSFTwo.finTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserSFTwo.finTemp.array[Obj.DoserSFTwo.finTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserDb.viscocity.array.length > 0) {
            Obj.DoserDb.viscocity.dateragnge = 'From: ' +  moment(Obj.DoserDb.viscocity.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserDb.viscocity.array[Obj.DoserDb.viscocity.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserDb.gelTemp.array.length > 0) {
            Obj.DoserDb.gelTemp.dateragnge = 'From: ' +  moment(Obj.DoserDb.gelTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserDb.gelTemp.array[Obj.DoserDb.gelTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
          if (Obj.DoserDb.finTemp.array.length > 0) {
            Obj.DoserDb.finTemp.dateragnge = 'From: ' +  moment(Obj.DoserDb.finTemp.array[0].dateValue).format('D-MMM-YY') + ' - To: ' + moment(Obj.DoserDb.finTemp.array[Obj.DoserDb.finTemp.array.length - 1].dateValue).format('D-MMM-YY')
          }
      }

        setfinalObj(Obj)
        console.log('FINAL Obj-------', Obj)
    }, [SelectedRecords]);


    // useEffect(() => {
    //   const createTempObject = (title) => ({
    //     dateragnge: '',
    //     isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
    //     title,
    //     array: [],
    //   });
    
    //   const createObj = () => ({
    //     DataSource: {
    //       RecordCount: SelectedRecordValue,
    //     },
    //     PlantData,
    //     StorageGraph: {
    //       viscocity: createTempObject("Holding Tank Viscosity"),
    //       gelTemp: createTempObject("Holding Tank Gel Temperature"),
    //       finTemp: createTempObject("Holding Tank Temperature"),
    //     },
    //     DoserData: {
    //       viscocity: createTempObject("Doser Viscosity"),
    //       gelTemp: createTempObject("Doser Gel Temperature"),
    //       finTemp: createTempObject("Doser Temperature"),
    //     },
    //     bBatchData: {
    //       viscocity: createTempObject("Batch Viscosity"),
    //       gelTemp: createTempObject("Batch Gel Temperature"),
    //       finTemp: createTempObject("Batch Finished Temperature"),
    //     },
    //     DoserSFOne: {
    //       viscocity: createTempObject("Doser SF #1 Viscosity"),
    //       gelTemp: createTempObject("Doser SF #1 Gel Temperature"),
    //       finTemp: createTempObject("Doser SF #1 Temperature"),
    //     },
    //     DoserSFTwo: {
    //       viscocity: createTempObject("Doser SF #2 Viscosity"),
    //       gelTemp: createTempObject("Doser SF #2 Gel Temperature"),
    //       finTemp: createTempObject("Doser SF #2 Temperature"),
    //     },
    //     DoserDb: {
    //       viscocity: createTempObject("Doser DB Viscosity"),
    //       gelTemp: createTempObject("Doser DB Gel Temperature"),
    //       finTemp: createTempObject("Doser DB Temperature"),
    //     },
    //     Chart: {
    //       Type: "Line",
    //       Title: "Monthly Sales Data",
    //       XAxis: {
    //         Title: "Date",
    //         DataField: "Date",
    //       },
    //     },
    //   });
    
    //   const Obj = createObj();
    
    //   const processRecord = ({ location, type, value, createdDate }) => {
    //     const isTemperature = type === 0 || type === 2; // 0: gelTemp, 2: finTemp
    //     const isViscocity = type === 1; // 1: viscocity
    //     let targetCategory;
    
    //     if (location === 1) {
    //       targetCategory = "StorageGraph";
    //     } else if (location === 0) {
    //       targetCategory = "bBatchData";
    //     } else {
    //       targetCategory = "DoserData";
    //     }
    
    //     if (Obj[targetCategory]) {
    //       if (isTemperature) {
    //         const tempKey = type === 0 ? "gelTemp" : "finTemp";
    //         if (Obj[targetCategory][tempKey].array.length < SelectedRecordValue) {
    //           Obj[targetCategory][tempKey].array.unshift({
    //             yVal: Obj[targetCategory][tempKey].array.length + 1,
    //             xVal: IsCelcius ? (((value - 32) * 5) / 9).toFixed(2) : value,
    //             dateValue: createdDate,
    //           });
    //         }
    //       }
    //       if (isViscocity && Obj[targetCategory].viscocity.array.length < SelectedRecordValue) {
    //         Obj[targetCategory].viscocity.array.unshift({
    //           yVal: Obj[targetCategory].viscocity.array.length + 1,
    //           xVal: value,
    //           dateValue: createdDate,
    //         });
    //       }
    //     }
    //   };
    
    //   if (SelectedRecords) {
    //     SelectedRecords.forEach(processRecord);
    
    //     const updateDateRange = (category) => {
    //       ['gelTemp', 'viscocity', 'finTemp'].forEach((type) => {
    //         const array = Obj[category][type].array;
    //         if (array.length > 0) {
    //           Obj[category][type].dateragnge = `From: ${moment(array[array.length - 1].dateValue).format('D-MMM-YY')} - To: ${moment(array[0].dateValue).format('D-MMM-YY')}`;
    //         }
    //       });
    //     };
    
    //     // Update date ranges for all relevant categories
    //     ['StorageGraph', 'bBatchData', 'DoserData', 'DoserSFOne', 'DoserSFTwo', 'DoserDb'].forEach(updateDateRange);
    //   }
    
    //   setfinalObj(Obj);
    //   console.log('FINAL Obj-------', Obj);
    // }, [SelectedRecords]);
    

        
        
        


    const Data ={ StarchGraphReport:JSON.stringify(finalObj)};
    const reportName=useReportName("Starch Graph Report", PlantData?.name, null);
    let reportData = {Loaded:true,Data:Data,ReportName:reportName,EmailSubject:'Theraml Images '+PlantData?.name};
    
    const handleClose = () => {
      nagivate('/PlantEdit/' + params.PlantID + "/" + 7);
    }
    if (finalObj === null) {
       return <div>Loading...</div>;
    } 
    return <ReportViewer type="StarchGraphReport" name="StarchGraphReport" printData={reportData} 
       onClose={handleClose}  />;
    
}
export default StarchChecksGraphPrint;