import Constants from '../../constants';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import moment from 'moment';
import  {useNavigate, useParams } from 'react-router-dom';
//MUI
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { GetHeatVesselByID,GetBoilers, GetHVTempsByHVID } from '../../selectors/HeatVesselSelectors';
import { KeyItemsFilteredByArray } from '../../selectors/KeyItemSelectors';
import { useReportUser,usePlantDataFromVisitID, useVisitData, useReportName, conImpReportName, conImpRepName } from '../../misc/UseFuncs';
import { MachinesFilteredByArray,MachinesAddFlutesString } from '../../selectors/PlantSelectors';
import { GetVisitDetailFromVisitId } from '../MetaData/PlantOptions';

import ReportViewer from '../Report/ReportViewer';
const constants=Constants;
const ConImpPrint = props => {
    const params=useParams();
    const navigate=useNavigate();
    const handleClose = () => {
        navigate(-1);
    }
    const ContImpID = params.ContImpID;
    const { DetailsObj} = useSelector(state => {
        console.log("statestatestatestatestate", state);
        return {
            DetailsObj: state.ContImprove.find(gr => gr.id == ContImpID)
        };
    }, shallowEqual);
    useEffect(() => {
        console.log('newDetailsObj------', DetailsObj);
    }, [DetailsObj]);
     const printData=useHeatVeseelPrintData(ContImpID, DetailsObj);
     if (!printData) {
        <div>Loading</div>
     }
     return <ReportViewer type="Baseline" name="Baseline" printData={printData} 
     onClose={handleClose}  />;
}
const formatnum = (num) => {
    let value = parseFloat(num)
    let formatted = Math.round(value);
    return parseFloat(formatted).toLocaleString();
}
const Tformatnum = (number) => {
  if (isNaN(number) || number === null) {
    return "Invalid Number";
}
if (Number.isInteger(number)) {
    let formattedNumber = number.toString()
            formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedNumber;
} else {
    let formattedNumber = Number(number).toFixed(2);
    formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedNumber;
}
}
const Newformatnum = (number) => {
    console.log("???????????????????????????????????????????????????????????????????????????????????", number);
    console.log('check 22222');
    let formattedNumber = Number(number).toFixed(2);
    formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedNumber;
}
export default ConImpPrint;
export const useHeatVeseelPrintData=(hvID, DetailsObj)=>{
    const visitObj = useVisitData(DetailsObj?.visitID);
    const plant = usePlantDataFromVisitID(DetailsObj?.visitID);
    const UserData = useReportUser();
    const Baseline = GetBaselineDetail(DetailsObj?.baselineID);
    const BaselineData = GetBaseLineData(DetailsObj);
    const wasteData = GetWasteData(DetailsObj);
    const TrainingData = GetTrainingData(DetailsObj);
    const DowntimeData = GetDownShiftData(DetailsObj);
    const ConsumptionData = GetConsumption(DetailsObj);
    const ProductionData = GetProduction(DetailsObj);
    const FoorterData = GetFooterData(DetailsObj, wasteData, TrainingData, DowntimeData, ConsumptionData, ProductionData, plant);
    console.log('DetailsObj--->', DetailsObj)
    console.log('Baseline--->', Baseline)
    console.log('UserData--->', UserData)
    console.log('plant--->', plant)
    console.log('visitObj--->', visitObj)
    console.log('wasteData--->', wasteData)
    console.log('BaselineData >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', BaselineData)
    const dJSON ={User:UserData, Plant:plant, Visit:visitObj, Baseline:BaselineData, Waste:wasteData, Training:TrainingData, Downtime:DowntimeData, Consumption:ConsumptionData, Production:ProductionData, FooterObject:FoorterData};
    const Data ={ Baseline:JSON.stringify(dJSON)};
    // reportName = plant.name +"_"+ formatDate1(new Date(DetailsObj.startDate)) + "-" + formatDate1(new Date(DetailsObj.enddate))
    // const reportName=conImpReportName(plant?.name,DetailsObj,plant?.company,plant?.location);
    const reportName=conImpRepName(UserData.fullName, plant?.name,DetailsObj,plant?.company,plant?.location,Baseline);
    // const reportName=useReportName("Baseline",plant?.name,visitObj);
    let visitDate=null;
    if(visitObj){
        const d=new Date(visitObj.startDate);
        visitDate=+(d.getMonth()+1).toString().padStart(2,"0")+"/"+d.getDate().toString().padStart(2,"0")+"/"+d.getFullYear();
    }
    console.log('SEND DATA--->', Data)
    return {Loaded:true,Data:Data,ReportName:reportName,EmailSubject:'Continuous ImprovementPrint '+plant?.name+' '+visitDate};
};

function GetBaselineDetail(BaselineId) {
    const plant = useSelector(state => state.Baselines.find(p => p.id === BaselineId), shallowEqual);
    return plant??{};
}
function GetPlantDetail(plantID) {
    const plant = useSelector(state => state.Plants.find(p => p.id === plantID), shallowEqual);
    return plant??{};
}

function GetTrainingData(DetailsObj) {
    let TrainObj = {
        IsTrainingVisible: GetCheckIsSelected('hoursOfTraining', DetailsObj),
        HourOfTraning: 0,
        DollarHour: 250,
        TotalCostSaved: 0,
        TotalCostSavedValue: 0,
        TimeTitle: ''
    }
    if (DetailsObj.hoursOfTraining > 0 ) {
        TrainObj.HourOfTraning = Tformatnum(DetailsObj.hoursOfTraining)
        TrainObj.TotalCostSaved = Tformatnum(TrainObj.DollarHour * TrainObj.HourOfTraning)
        TrainObj.TotalCostSavedValue = Tformatnum(TrainObj.DollarHour * TrainObj.HourOfTraning)
        TrainObj.TimeTitle = formatDate(DetailsObj.startDate, DetailsObj.enddate, DetailsObj.periodValues, DetailsObj.selectedRadioValue)
    }
    return TrainObj
}
function formatDate1(date) {
    const month = new Date(date).getMonth() + 1; // Month is zero-based, so we add 1
    const day = new Date(date).getDate();
    const year = new Date(date).getFullYear() % 100; // Get last two digits of the year

    // Construct the formatted date string
    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
}
function GetDownShiftData(DetailsObj) {
    let TrainObj = {
        IsShowDowntime: GetCheckIsSelected('hoursOfDownTime', DetailsObj),
        HoursDTSaved : 0,
		TotalLineal: 0,
		TotalSqFt: 0,
		MsgDay: 0,
		LbsDay: 0,
		DollarPerSaved: 0,
        TimeTitle: ''
    }
    if (DetailsObj.hoursOfDownTime > 0 ) {
        const selectedBaseline = GetBaselineDetail(DetailsObj?.baselineID);
        let avgBgCost = constants.BLine.BasisWeight[selectedBaseline.baseGrade]
        let costMSFWperPP = ((selectedBaseline.preprint / 100 * (avgBgCost * 2.5)) + (((1 - selectedBaseline.preprint / 100) * avgBgCost) * selectedBaseline.costRate))
        TrainObj.HoursDTSaved = DetailsObj.hoursOfDownTime
        TrainObj.TotalLineal = DetailsObj.hoursOfDownTime * selectedBaseline.avgSpeed
        TrainObj.TotalSqFt = TrainObj.TotalLineal * (selectedBaseline.avgWidth/12) 
        TrainObj.MsgDay = TrainObj.TotalSqFt / 1000
        TrainObj.LbsDay = (TrainObj.TotalSqFt / 1000) * selectedBaseline.baseGrade
        TrainObj.DollarPerSaved = ((TrainObj.TotalSqFt / 1000) * costMSFWperPP)
        TrainObj.TimeTitle = formatDate(DetailsObj.startDate, DetailsObj.enddate, DetailsObj.periodValues, DetailsObj.selectedRadioValue)
    }
    let data = {
        IsShowDowntime: TrainObj.IsShowDowntime,
        TimeTitle: TrainObj.TimeTitle,
        HoursDTSaved: Tformatnum(TrainObj.HoursDTSaved),
        TotalLineal: formatnum(TrainObj.TotalLineal),
        TotalSqFt: formatnum(TrainObj.TotalSqFt),
        MsgDay: formatnum(TrainObj.MsgDay),
        LbsDay: formatnum(TrainObj.LbsDay),
        DollarPerSaved: Tformatnum(TrainObj.DollarPerSaved),
        DollarPerSavedValue: TrainObj.DollarPerSaved,
    }
    return data
}
function GetFooterData(DetailsObj, wasteData, TrainingData, DowntimeData, ConsumptionData, ProductionData, plantDetail) {
    // console.log('111111111111111111111111111111wasteData', wasteData)
    // console.log('111111111111111111111111111111TrainingData', TrainingData)
    // console.log('111111111111111111111111111111DowntimeData', DowntimeData)
    // console.log('111111111111111111111111111111ConsumptionData', ConsumptionData)
    // console.log('111111111111111111111111111111ProductionData', ProductionData)
    let Object = {
        TotalSavings: 0,
        Waste: 0,
        Production: 0,  
        Consumption: 0,
        Downtime: 0,  
        Training: 0,
        GrandTotal: 0,
        SupplierName: 'Corrugated Chemicals Inc.',
        TimeTitle: '',
        FormatedDate: formatDate(DetailsObj.startDate, DetailsObj.enddate, DetailsObj.periodValues, DetailsObj.selectedRadioValue),
        SelectedRadio: DetailsObj.selectedRadioValue,
        ProjectSummary: "",
        IsShowProjectSummary: false,
        SavedBy: DetailsObj.signatureCaptureBy,
        SignatureTitle: '',
        UserSignatureTitle: DetailsObj.signatureCaptureBy,
        UserSignatureBASE64: DetailsObj.signatureCapture,
        UserSignatureTime: DetailsObj.userSignatureCaptureTime,
        GMSRepresentSignatureUserName: DetailsObj.gmRepDisplayUserName,
        GMSRepresentSignatureTitle: plantDetail.name,
        GMSRepresentSignatureBASE64: DetailsObj.gmSignatureData,
        GMSRepresentSignatureTime: DetailsObj.gmSignatureCaptureTime,
        isShowLeftSign: true,
        isShowRightSign: true,
        PositionName: '----',
        startTime: DetailsObj.startDate,
        endTime: DetailsObj.enddate
    }
    let start = new Date(DetailsObj.startDate);
    let end = new Date(DetailsObj.enddate);
    let startMonth = start.toLocaleString('default', { month: 'long' });
    let endMonth = end.toLocaleString('default', { month: 'long' });
    let formattedDate;
    if (startMonth === endMonth) {
        if (start.getFullYear() === end.getFullYear()) {
            formattedDate = `${startMonth} ${start.getDate()}-${end.getDate()}, ${start.getFullYear()}`;
        } else {
            formattedDate = `${startMonth} ${start.getDate()} ${start.getFullYear()}, ${endMonth} ${end.getDate()} ${end.getFullYear()}`;
        }
    } else {
        formattedDate = `${startMonth} ${start.getDate()} - ${endMonth} ${end.getDate()}, ${start.getFullYear()}`;
    }
    if (DetailsObj.projectSummary !== '' && DetailsObj.projectSummary !== null) {
        Object.IsShowProjectSummary = true
        let formattedData = JSON.parse(DetailsObj.projectSummary).map(item => `<span>• ${item}</span>`).join('<br>'); // <br> tag add kiya gaya hai taki har summary ek alag line par ho
        Object.ProjectSummary = formattedData;
    } else {
        Object.IsShowProjectSummary = false
    }
    Object.TimeTitle = formatDate(DetailsObj.startDate, DetailsObj.enddate, DetailsObj.periodValues, DetailsObj.selectedRadioValue)
        
    Object.Waste = wasteData.WasteSavingValue
    Object.Training = TrainingData.TotalCostSavedValue
    Object.Downtime = DowntimeData.DollarPerSavedValue
    Object.Production = ProductionData.MainCount
    Object.Consumption = ConsumptionData.TotalMainCountValue
    // let value2 = (DetailsObj.totalWasteSavings + TrainingData.TotalCostSaved + parseInt(DowntimeData.DollarPerSaved) + parseInt(ProductionData.MainCount) + ConsumptionData.TotalMainCount)
    

    // Object.GrandTotal = (DetailsObj.totalWasteSavings + DetailsObj.totalProductionSaving + DetailsObj.totalConsumptionSavings + DetailsObj.totalTrainingCostSaved + DetailsObj.totalDownTimeSaved)
    // console.log('Object.GrandTotal', typeof Object.GrandTotal)
    // if (Object.GrandTotal >= 100000) {
    //     DetailsObj.isSignedByGM = true
    // }
    // if (DetailsObj.isSignedByGM == false) {
    //     Object.SignatureTitle = plantDetail.name + ' Representative / Date'
    // } else {
    //     Object.SignatureTitle =  plantDetail.name + ' GM / Date'
    // }

    let total = 0
    if (wasteData.IsWasteVisible) {
        total += DetailsObj.totalWasteSavings
    }
    if (ProductionData.IsProductionVisible) {
        total += DetailsObj.totalProductionSaving
    }
    if (ConsumptionData.IsConsumptionVisible) {
        total += DetailsObj.totalConsumptionSavings
    }
    if (TrainingData.IsTrainingVisible) {
        total += DetailsObj.totalTrainingCostSaved
    }
    if (DowntimeData.IsShowDowntime) {
        total += DetailsObj.totalDownTimeSaved
    }
    Object.GrandTotal = total
    if (Object.GrandTotal >= 100000) {
        Object.SignatureTitle = plantDetail.name + ' GM / Date'
    } else {
        Object.SignatureTitle = plantDetail.name + ' / Date'
    }
    const plantDet = GetPlantDetail(DetailsObj?.plantID);
    // console.log('plantplantplantplantplant', plantDet.usrareamanagerId)
    // console.log('plantplantplantplantplant', DetailsObj)
    // console.log('plantplantplantplantplant', DetailsObj.createdByID)
    if (DetailsObj.createdByID !== "00000000-0000-0000-0000-000000000000") {
        if (plantDet.usrareamanagerId === DetailsObj.createdByID) {
            Object.PositionName = 'Regional Manager'
        } else if (plantDet.usrprimarytechId === DetailsObj.createdByID) {
            Object.PositionName = 'Service Lead'
        } else if (plantDet.usrsecondarytechId === DetailsObj.createdByID) {
            Object.PositionName = 'Secondary Tech'
        } else if (plantDet.usrtertiarytechId === DetailsObj.createdByID) {
            Object.PositionName = 'Tertiary Tech'
        } else if (plantDet.ownerId === DetailsObj.createdByID) {
            Object.PositionName = 'Owner'
        }
    } else {
        if (plantDet.usrareamanagerId === DetailsObj.updatedByID) {
            Object.PositionName = 'Regional Manager'
        } else if (plantDet.usrprimarytechId === DetailsObj.updatedByID) {
            Object.PositionName = 'Service Lead'
        } else if (plantDet.usrsecondarytechId === DetailsObj.updatedByID) {
            Object.PositionName = 'Secondary Tech'
        } else if (plantDet.usrtertiarytechId === DetailsObj.updatedByID) {
            Object.PositionName = 'Tertiary Tech'
        } else if (plantDet.ownerId === DetailsObj.updatedByID) {
            Object.PositionName = 'Owner'
        }
    }
    if (Object.UserSignatureBASE64 === '') {
        // Object.isShowLeftSign = false
        Object.UserSignatureTime = ''
        // Object.UserSignatureTitle = ''
        // Object.PositionName = ''
        // Object.SupplierName = ''
    }
    if (Object.GMSRepresentSignatureBASE64 === '') {
        // Object.isShowRightSign = false
        // Object.GMSRepresentSignatureUserName = ''
        // Object.GMSRepresentSignatureTitle = ''
        Object.GMSRepresentSignatureTime = ''
        // Object.SignatureTitle = ''
    }
    if (DetailsObj.gmRepDisplayUserName === '') {
        Object.GMSRepresentSignatureUserName = '_________________________________'
    }

    return Object
}
function GetProduction(DetailsObj) {
    let ProObj = {
        IsProductionVisible: GetCheckIsSelected('production', DetailsObj),
        BaselineLFPH : 0,
        BaselineTotalLineal: 0,
        BaselineTotalsqFt: 0,
        BaselineMsfHour: 0,
        BaselineLbsHour: 0,
        BaselineDollarHour: 0,
        NewLinealperHour: 0,
        NlphTotalLineal: 0,
        NlphTotalsqFt: 0,
        NlphMsfHour: 0,
        NlphLbsHour: 0,
        NlphDollarHour: 0,
        MoreSalesHR: 0,
		IncreaseMsfHR: 0,
        MSFImproved: 0,
        TotalSalesIncrease: 0,
        CostReductionProfit: 0,
        TotalSaving: 0,
        valueCounting:'',
        TimeTitle: '',
        RadioValue: '',
        MainCount: '',
    }
    if (DetailsObj.proNewLinealPerHr > 0 && DetailsObj.proTotalCount > 0) {
        const selectedBaseline = GetBaselineDetail(DetailsObj?.baselineID);
        let avgBgCost = constants.BLine.BasisWeight[selectedBaseline.baseGrade]
        let costMSFWperPP = ((selectedBaseline.preprint / 100 * (avgBgCost * 2.5)) + (((1 - selectedBaseline.preprint / 100) * avgBgCost) * selectedBaseline.costRate))
        ProObj.BaselineLFPH = selectedBaseline.avgSpeed
        ProObj.BaselineTotalLineal = selectedBaseline.avgSpeed * 24
        ProObj.BaselineTotalsqFt =  ProObj.BaselineTotalLineal * (selectedBaseline.avgWidth / 12)
        ProObj.BaselineMsfHour =  ProObj.BaselineTotalsqFt / 24 / 1000
        ProObj.BaselineLbsHour =  (ProObj.BaselineTotalsqFt / 24 / 1000) * selectedBaseline.baseGrade
        ProObj.BaselineDollarHour =  ((ProObj.BaselineTotalsqFt / 24 / 1000) * costMSFWperPP)
        ProObj.NewLinealperHour =  DetailsObj.proNewLinealPerHr
        ProObj.NlphTotalLineal =  DetailsObj.proNewLinealPerHr * 24
        ProObj.NlphTotalsqFt =  ProObj.NlphTotalLineal * (selectedBaseline.avgWidth / 12)
        ProObj.NlphMsfHour =  ProObj.NlphTotalsqFt / 24 / 1000
        ProObj.NlphLbsHour =  (ProObj.NlphTotalsqFt / 24 / 1000) * selectedBaseline.baseGrade
        ProObj.NlphDollarHour =  ((ProObj.NlphTotalsqFt / 24 / 1000) * costMSFWperPP)
        ProObj.IncreaseMsfHR = ProObj.NlphMsfHour - ProObj.BaselineMsfHour
        ProObj.MoreSalesHR = (ProObj.NlphDollarHour - ProObj.BaselineDollarHour)
        let MsfImproved = ((ProObj.NlphTotalsqFt / 24 / 1000) - (ProObj.BaselineTotalsqFt / 24 / 1000)) * (selectedBaseline.hoursPerShift * selectedBaseline.shiftPerDay)
        let TotalSalesInc = MsfImproved * costMSFWperPP
        if (DetailsObj.selectedRadioValue === 'day') {
            ProObj.MSFImproved = MsfImproved
            ProObj.TotalSalesIncrease = MsfImproved * costMSFWperPP
            ProObj.CostReductionProfit = (MsfImproved * costMSFWperPP) * 0.3
            ProObj.TotalSaving = ((TotalSalesInc * 0.3) * DetailsObj.proTotalCount)
            ProObj.MainCount = ((TotalSalesInc * 0.3) * DetailsObj.proTotalCount)
        } else if (DetailsObj.selectedRadioValue === 'week') {
            ProObj.MSFImproved = MsfImproved * selectedBaseline.daysPerWeek
            ProObj.TotalSalesIncrease = (MsfImproved * selectedBaseline.daysPerWeek) * costMSFWperPP
            ProObj.CostReductionProfit = (MsfImproved * selectedBaseline.daysPerWeek) * costMSFWperPP * 0.3
            ProObj.TotalSaving = ((((MsfImproved * selectedBaseline.daysPerWeek) * costMSFWperPP) * 0.3) * DetailsObj.proTotalCount)
            ProObj.MainCount = ((((MsfImproved * selectedBaseline.daysPerWeek) * costMSFWperPP) * 0.3) * DetailsObj.proTotalCount)
        } else if (DetailsObj.selectedRadioValue === 'month') {
            ProObj.MSFImproved = MsfImproved * selectedBaseline.daysPerWeek * 4.25
            ProObj.TotalSalesIncrease = (MsfImproved * selectedBaseline.daysPerWeek * 4.25) * costMSFWperPP
            ProObj.CostReductionProfit = (MsfImproved * selectedBaseline.daysPerWeek * 4.25) * costMSFWperPP * 0.3
            ProObj.TotalSaving = ((((MsfImproved * selectedBaseline.daysPerWeek * 4.25) * costMSFWperPP) * 0.3) * DetailsObj.proTotalCount)
            ProObj.MainCount = ((((MsfImproved * selectedBaseline.daysPerWeek * 4.25) * costMSFWperPP) * 0.3) * DetailsObj.proTotalCount)
        }
        ProObj.TimeTitle = formatDate(DetailsObj.startDate, DetailsObj.enddate, DetailsObj.periodValues, DetailsObj.selectedRadioValue)
        ProObj.RadioValue = DetailsObj.selectedRadioValue
        ProObj.valueCounting = DetailsObj.periodValues
    }

    let data = {
        IsProductionVisible: ProObj.IsProductionVisible,
        TimeTitle: ProObj.TimeTitle,
        valueCounting: ProObj.valueCounting,
        RadioValue: ProObj.RadioValue,
        MainCount: ProObj.MainCount,
        BaselineLFPH: formatnum(ProObj.BaselineLFPH),
        BaselineTotalLineal: formatnum(ProObj.BaselineTotalLineal),
        BaselineTotalsqFt: formatnum(ProObj.BaselineTotalsqFt),
        BaselineMsfHour: formatnum(ProObj.BaselineMsfHour),
        BaselineLbsHour: formatnum(ProObj.BaselineLbsHour),
        BaselineDollarHour: Tformatnum(ProObj.BaselineDollarHour),
        NewLinealperHour: formatnum(ProObj.NewLinealperHour),
        NlphTotalLineal: formatnum(ProObj.NlphTotalLineal),
        NlphTotalsqFt: formatnum(ProObj.NlphTotalsqFt),
        NlphMsfHour: formatnum(ProObj.NlphMsfHour),
        NlphLbsHour: formatnum(ProObj.NlphLbsHour),
        NlphDollarHour: Tformatnum(ProObj.NlphDollarHour),
        MoreSalesHR: Tformatnum(ProObj.MoreSalesHR),
        IncreaseMsfHR: formatnum(ProObj.IncreaseMsfHR),
        MSFImproved: formatnum(ProObj.MSFImproved),
        TotalSalesIncrease: formatnum(ProObj.TotalSalesIncrease),
        CostReductionProfit: formatnum(ProObj.CostReductionProfit),
        TotalSaving: Tformatnum(ProObj.TotalSaving),
    }

    return data
}
function GetConsumption(DetailsObj) {
    let CompObj = {
        IsConsumptionVisible: GetCheckIsSelected('consumption', DetailsObj),
        BaselineLbMsf: 0,
        NewLbMsf: 0,
        DifferenceLbMsf: 0,
        CostLbLbMsf: 0,
        StarchLbUsage: 0,
        NewStarchLbUsage: 0,
        StarchLbSavings: 0,
        TotalSavings: 0,
        TotalMainCount: 0,
        TimeTitle: '',
        RadioValue: '',
        valueCounting:'',
    }
    if (DetailsObj.consLbPerMsf > 0 && DetailsObj.consCostLb > 0 && DetailsObj.consTotalCount > 0) {
        const selectedBaseline = GetBaselineDetail(DetailsObj?.baselineID);
        let StarchIbUsage = selectedBaseline.consumptionBaseline * (selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000) * (selectedBaseline.shiftPerDay * selectedBaseline.hoursPerShift)
        let NewStarchIbUsage = (DetailsObj.consLbPerMsf * (selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000)) * (selectedBaseline.shiftPerDay * selectedBaseline.hoursPerShift)
        let msfProducedMonth = selectedBaseline.daysPerWeek * 4.25
        let msfProducedWeek = selectedBaseline.daysPerWeek
        let TotalConsumptionSaving = 0
        CompObj.BaselineLbMsf = selectedBaseline.consumptionBaseline
        CompObj.NewLbMsf = DetailsObj.consLbPerMsf
        CompObj.DifferenceLbMsf = (selectedBaseline.consumptionBaseline - DetailsObj.consLbPerMsf)
        CompObj.CostLbLbMsf = DetailsObj.consCostLb
        CompObj.TotalMainCount = 0
        if (DetailsObj.selectedRadioValue === 'day') {
            CompObj.StarchLbUsage = StarchIbUsage
            CompObj.NewStarchLbUsage = NewStarchIbUsage
            CompObj.StarchLbSavings = StarchIbUsage - NewStarchIbUsage
            CompObj.TotalSavings = (DetailsObj.consCostLb *  (StarchIbUsage - NewStarchIbUsage))
            CompObj.TotalMainCount = (DetailsObj.consCostLb *  (StarchIbUsage - NewStarchIbUsage)) * DetailsObj.consTotalCount

        } else if (DetailsObj.selectedRadioValue === 'week') {
            CompObj.StarchLbUsage = StarchIbUsage * msfProducedWeek
            CompObj.NewStarchLbUsage = NewStarchIbUsage * msfProducedWeek
            CompObj.StarchLbSavings = (StarchIbUsage * msfProducedWeek) - (NewStarchIbUsage * msfProducedWeek)
            let a = (StarchIbUsage * msfProducedWeek) - (NewStarchIbUsage * msfProducedWeek)
            CompObj.TotalSavings = (DetailsObj.consCostLb * CompObj.StarchLbSavings)
            CompObj.TotalMainCount = ((DetailsObj.consCostLb * a) * DetailsObj.consTotalCount)
        } else if (DetailsObj.selectedRadioValue === 'month') {
            CompObj.StarchLbUsage = StarchIbUsage * msfProducedMonth
            CompObj.NewStarchLbUsage = NewStarchIbUsage * msfProducedMonth
            CompObj.StarchLbSavings = (StarchIbUsage * msfProducedMonth) - (NewStarchIbUsage * msfProducedMonth)
            CompObj.TotalSavings = (DetailsObj.consCostLb * CompObj.StarchLbSavings)
            CompObj.TotalMainCount = (DetailsObj.consCostLb *  ((StarchIbUsage * msfProducedMonth) - (NewStarchIbUsage * msfProducedMonth))) * DetailsObj.consTotalCount
        }
        CompObj.TimeTitle = formatDate(DetailsObj.startDate, DetailsObj.enddate, DetailsObj.periodValues, DetailsObj.selectedRadioValue)
        CompObj.RadioValue = DetailsObj.selectedRadioValue
        CompObj.valueCounting = DetailsObj.periodValues
    }
    let data = {
        IsConsumptionVisible: CompObj.IsConsumptionVisible,
        TimeTitle: CompObj.TimeTitle,
        RadioValue: CompObj.RadioValue,
        valueCounting: CompObj.valueCounting,

        BaselineLbMsf: Tformatnum(CompObj.BaselineLbMsf),
        NewLbMsf: Tformatnum(CompObj.NewLbMsf),
        DifferenceLbMsf: Tformatnum(CompObj.DifferenceLbMsf),
        CostLbLbMsf: Tformatnum(CompObj.CostLbLbMsf),
        StarchLbUsage: formatnum(CompObj.StarchLbUsage),
        NewStarchLbUsage: formatnum(CompObj.NewStarchLbUsage),
        StarchLbSavings: formatnum(CompObj.StarchLbSavings),
        TotalSavings: Tformatnum(CompObj.TotalSavings),
        TotalMainCount: Tformatnum(CompObj.TotalMainCount),
        TotalMainCountValue: CompObj.TotalMainCount,
        
    }
    return data
}
function formatDateTodate(date) {
    if (date !== '') {
     const month = new Date(date).getMonth() + 1; // Month is zero-based, so we add 1
     const day = new Date(date).getDate();
     const year = new Date(date).getFullYear() % 100; // Get last two digits of the year
 
     // Construct the formatted date string
     const formattedDate = `${month}/${day}/${year}`;
 
     return formattedDate;
    } else {
     return ''
    }
 }
function GetBaseLineData(DetailsObj) {
    const selectedBaseline = GetBaselineDetail(DetailsObj?.baselineID);
    console.log('BaselineBaselineBaseline------>', selectedBaseline)
    let baselineData = {
        BaselineTabTitle  : 'Plant Production Info Baseline' + ` ${ selectedBaseline.baselineStartDate !== '' ? ': (From ' + selectedBaseline.baselineStartDate + ' to ' + selectedBaseline.baselineEndDate + ')' : ''}`,
        ID: selectedBaseline.id,
        PlantID: selectedBaseline.plantID,
        BaselineName: selectedBaseline.baselineName,
        AvgSpeed: selectedBaseline.avgSpeed,
        DaysPerWeek: selectedBaseline.daysPerWeek,
        ShiftPerDay: selectedBaseline.shiftPerDay,
        HoursPerShift: selectedBaseline.hoursPerShift,
        NoOfMonth: selectedBaseline.noOfMonth,
        AvgWidth: selectedBaseline.avgWidth,
        ActualPrcWaste: Tformatnum(selectedBaseline.actualPrcWaste),
        Preprint: selectedBaseline.preprint,
        BaseGrade: selectedBaseline.baseGrade,
        CostRate: selectedBaseline.costRate,
        DayPerMonth: '',
        MSFPerHour: '',
        DayPerYear: '',
        AvgBgCost: '',
        CostMSFWPP: '',
        TotalHours: '',
        CountMargin: '',
        SalesPrice: 120,
        ConsumptionBaseline: selectedBaseline.consumptionBaseline,
        Deleted: selectedBaseline.deleted,
        CreatedDate: selectedBaseline.createdDate,
        LastSyncDate: selectedBaseline.lastSyncDate,
        CreatedByID: selectedBaseline.createdByID,
        CreatedBy: selectedBaseline.createdBy,
        UpdatedByID: selectedBaseline.updatedByID,
        UpdatedBy: selectedBaseline.updatedBy,
    }
    let avgBgCost = constants.BLine.BasisWeight[selectedBaseline.baseGrade]
    baselineData.DayPerMonth = selectedBaseline.daysPerWeek * 4.25
    
    baselineData.MSFPerHour = formatnum((selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000))
    baselineData.DayPerYear = (selectedBaseline.daysPerWeek * 4.25) * 12
    baselineData.AvgBgCost = Math.floor(avgBgCost)
    baselineData.CostMSFWPP = ((selectedBaseline.preprint / 100 * (avgBgCost * 2.5)) + (((1 - selectedBaseline.preprint / 100) * avgBgCost) * selectedBaseline.costRate)).toFixed(2)
    baselineData.TotalHours = 0
    baselineData.CountMargin = 120 - baselineData.CostMSFWPP
    return baselineData;
}
function GetWasteData(DetailsObj) {
    console.log('WASTE123--->', DetailsObj)
    let WasteObj = {
        IsWasteVisible: GetCheckIsSelected('waste', DetailsObj),
        ActualWaste: 0,
        NewWaste: 0,
        MSFProduced: 0,
        WasteReduction: 0,
        TotalCostReduction: 0,
        WasteSaving: 0,
        valueCounting: 0,
        TimeTitle: '',
        RadioValue: ''
    }
    const selectedBaseline = GetBaselineDetail(DetailsObj?.baselineID);
    if (DetailsObj.newPerWaste > 0 && DetailsObj.wasteTotalCount > 0) {
        WasteObj.ActualWaste = selectedBaseline.actualPrcWaste
        WasteObj.RadioValue = DetailsObj.selectedRadioValue
        WasteObj.valueCounting = DetailsObj.periodValues
        WasteObj.NewWaste = DetailsObj.newPerWaste
        let westReduction = selectedBaseline.actualPrcWaste - DetailsObj.newPerWaste
        let avgBgCost = constants.BLine.BasisWeight[selectedBaseline.baseGrade]
        let costMSFWperPP = ((selectedBaseline.preprint / 100 * (avgBgCost * 2.5)) + (((1 - selectedBaseline.preprint / 100) * avgBgCost) * selectedBaseline.costRate))
        let msf = selectedBaseline.shiftPerDay * selectedBaseline.hoursPerShift * (selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000)
        if (DetailsObj.selectedRadioValue === 'day') {
            WasteObj.MSFProduced = msf
            WasteObj.NewWaste = DetailsObj.newPerWaste
            WasteObj.WasteReduction = westReduction
            WasteObj.TotalCostReduction = (westReduction / 100 * WasteObj.MSFProduced * costMSFWperPP)
            WasteObj.WasteSaving = (DetailsObj.totalWasteSavings)
            
        } else if (DetailsObj.selectedRadioValue === 'week') {
            WasteObj.MSFProduced = msf * selectedBaseline.daysPerWeek
            WasteObj.NewWaste = DetailsObj.newPerWaste
            WasteObj.WasteReduction = (selectedBaseline.actualPrcWaste - WasteObj.NewWaste)
            WasteObj.TotalCostReduction = (WasteObj.WasteReduction / 100 * WasteObj.MSFProduced * costMSFWperPP)
            WasteObj.WasteSaving = (DetailsObj.totalWasteSavings)
        } else if (DetailsObj.selectedRadioValue === 'month') {
            WasteObj.MSFProduced = ((selectedBaseline.daysPerWeek * 4.25) * msf)
            WasteObj.NewWaste = DetailsObj.newPerWaste
            WasteObj.WasteReduction = selectedBaseline.actualPrcWaste - WasteObj.NewWaste
            WasteObj.TotalCostReduction = (WasteObj.WasteReduction / 100 * WasteObj.MSFProduced * costMSFWperPP)
            WasteObj.WasteSaving = (DetailsObj.totalWasteSavings)
        }
    }
    WasteObj.TimeTitle = formatDate(DetailsObj.startDate, DetailsObj.enddate, DetailsObj.periodValues, DetailsObj.selectedRadioValue)

    let data = {
        IsWasteVisible : WasteObj.IsWasteVisible,
        ActualWaste : Tformatnum(WasteObj.ActualWaste),
        NewWaste : Tformatnum(WasteObj.NewWaste),
        MSFProduced : formatnum(WasteObj.MSFProduced),
        WasteReduction : Tformatnum(WasteObj.WasteReduction),
        TotalCostReduction : formatnum(WasteObj.TotalCostReduction),
        WasteSaving : Tformatnum(WasteObj.WasteSaving),
        WasteSavingValue : WasteObj.WasteSaving,
        valueCounting : formatnum(WasteObj.valueCounting),
        TimeTitle : WasteObj.TimeTitle,
        RadioValue : WasteObj .RadioValue,
    }


    return data;
}

function GetCheckIsSelected(from, DetailsObj) {
    if (from === 'waste') {
        if (DetailsObj.newPerWaste > 0 ) {
            return true
        } else {
            return false
        }
    } else if (from === 'production') {
        if (DetailsObj.proNewLinealPerHr > 0 ) {
            return true
        } else {
            return false
        }
    } else if (from === 'consumption') {
        if (DetailsObj.consLbPerMsf > 0 && DetailsObj.consCostLb > 0) {
            return true
        } else {
            return false
        }
    } else if (from === 'hoursOfTraining') {
        if (DetailsObj.hoursOfTraining > 0 ) {
            return true
        } else {
            return false
        }
    } else if (from === 'hoursOfDownTime') {
        if (DetailsObj.hoursOfDownTime > 0 ) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
    
}

function formatDate(startDate, endDate, count, isFrom) {
    // Convert string dates to Date objects
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
  
    // Format dates as MM/DD/YY
    const formattedStartDate = `${startDateObj.getMonth() + 1}/${startDateObj.getDate()}/${startDateObj.getFullYear() % 100}`;
    const formattedEndDate = `${endDateObj.getMonth() + 1}/${endDateObj.getDate()}/${endDateObj.getFullYear() % 100}`;
  
    let formattedString = `${formattedStartDate} to ${formattedEndDate}`;
  
    if (isFrom === 'day') {
      formattedString += ` (${count} Days)`;
    } else if (isFrom === 'week') {
      formattedString += ` (${count} Weeks)`;
    } else {
        formattedString += ` (${count} Months)`;
    }
  
    return formattedString;
  }